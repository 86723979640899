<template>
  <div>
    <v-checkbox
      color="success"
      :label="label"
      v-model="tournament.membersOnly"
      :disabled="disabled"
      readonly
      @click.stop="onClick"
      :loading="loading"
    ></v-checkbox>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <!-- Toolbar -->
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>
            Membership Requirement
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color2Text"
            text icon
            @click.stop="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <loading-bar :value="loading"></loading-bar>
        <v-card-text>
          <v-expand-transition>
            <div v-if="!loading">
              <v-radio-group v-model="tournament.membershipUsername">
                <v-radio
                  v-for="o in options"
                  :key="o.id"
                  :label="o.text"
                  :value="o.value"
                  color="success"
                ></v-radio>
              </v-radio-group>
              <div v-if="selectedOption && selectedOption.levels && selectedOption.levels.length">
                <v-row>
                  <v-col>Accepted Memberships</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-switch
                      label="Accept All"
                      v-model="allLevels"
                      :readonly="allLevels"
                      hide-details
                      class="mt-0"
                      color="success"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row v-for="level in selectedOption.levels" :key="level">
                  <v-col cols="1"></v-col>
                  <v-col cols="4" class="py-0">
                    <v-checkbox
                      :label="level"
                      v-model="tournament.membershipLevels"
                      :value="level"
                      multiple
                      hide-details
                      class="mt-0"
                      color="success"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="7" class="py-0">
                    <v-checkbox
                      label="Override Sanctioning"
                      v-model="tournament.membershipLevelsOverrideSanction"
                      :value="level"
                      multiple
                      hide-details
                      class="mt-0"
                      color="success"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions class="d-block">
          <div class="text-center">
            <v-btn color="success" @click.stop="dialog = false">Save</v-btn>
          </div>
          <div class="text-end">
            <v-btn
              color="error"
              text x-small
              @click.stop="removeClick"
            >remove members only requirement</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatArrayOr } from '@/Filters'

export default {
  props: ['tournament', 'disabled'],
  data () {
    return {
      dialog: false,
      loading: false,
      options: null,
      mp: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    label () {
      var levels = this.tournament.membershipLevels ? ` ${formatArrayOr(this.tournament.membershipLevels)} ` : ''
      return `${this.membershipUsername ? this.membershipUsername.toUpperCase() + ' ' : ''}${levels}Members Only`
    },
    membershipUsername () {
      return this.tournament.membershipUsername
    },
    isOwn () {
      return this.tournament.organization.membership && (!this.tournament.membershipUsername || this.tournament.membershipUsername.toLowerCase() === this.tournament.organization.username.toLowerCase())
    },
    ownOption () {
      return this.tournament.organization.membership && this.options && this.options.find(f => f.value.toLowerCase() === this.tournament.organization.username.toLowerCase())
    },
    selectedOption () {
      return this.options && this.options.find(f => f.value === this.membershipUsername)
    },
    allLevels: {
      get () {
        return !this.tournament.membershipLevels
      },
      set (val) {
        if (val) {
          this.tournament.membershipLevels = []
        }
      }
    }
  },
  methods: {
    onClick () {
      if (this.disabled) return
      if (!this.isOwn || !this.tournament.organization.membership) {
        this.dialog = true
        return
      }
      if (this.tournament.membersOnly) {
        this.removeMembersOnly('on-click')
      } else {
        this.setMembersOnly()
      }
    },
    removeMembersOnly (v) {
      if (v && v === 'on-click' && this.ownOption) {
        if (this.ownOption.levels && this.ownOption.levels.length > 0) {
          this.dialog = true
          return
        }
      }
      this.tournament.membersOnly = false
      this.tournament.membershipUsername = false
      this.tournament.membershipLevels = false
      this.tournament.membershipLevelsOverrideSanction = false
    },
    setMembersOnly () {
      this.tournament.membersOnly = true
      if (this.ownOption) {
        this.tournament.membershipUsername = this.ownOption.value
        if (this.ownOption.levels && this.ownOption.levels.length > 0) {
          this.dialog = true
        }
      } else {
        this.dialog = true
      }
    },
    removeClick () {
      this.removeMembersOnly()
      this.dialog = false
    },
    getOptions () {
      this.loading = true
      this.$http.get('/membership/options')
        .then(r => {
          this.options = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function (val) {
      if (!this.options) this.getOptions()
    }
  },
  mounted () {
    this.getOptions()
  }
}
</script>
